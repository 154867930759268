import React from "react";
import s01 from "../../img/ARCH/Arch_JBD/01.jpg";
import s02 from "../../img/ARCH/Arch_JBD/02.jpg";
import s03 from "../../img/ARCH/Arch_JBD/03.jpg";
import s04 from "../../img/ARCH/Arch_JBD/04.jpg";
import s05 from "../../img/ARCH/Arch_JBD/05.jpg";
import s06 from "../../img/ARCH/Arch_JBD/06.jpg";
import s07 from "../../img/ARCH/Arch_JBD/07.jpg";
import s08 from "../../img/ARCH/Arch_JBD/08.jpg";
import "../../css/archi.css";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IArchJbdkState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const ArchJbdComponent: React.FC<IArchJbdkState> = () => {
  const [state, setState] = React.useState<IArchJbdkState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02, s03, s04, s05, s06, s07, s08];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IArchJbdkState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickForward()}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickBackward()}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <h1>JBD</h1>
            <span style={{ color: "grey" }}>_Architektur_Wettbewerb</span>
            <h4>Less Plus und VIR.works | 2020</h4>
            <span style={{ color: "grey" }}>
              Wettbewerbs-Kooperation mit dem Büro Less Plus aus Dortmund für
              die Neuplanung des Kinder- und Jugendtheaters Dortmund.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Jugend ist geprägt durch das digitale Zeitalter. Mediale Räume
              und das virtuelle Netzwerk bilden den Mittelpunkt der zukünftigen
              Generation. Diese Entwicklung wird zum Leitgedanken des Entwurfes
              und spiegelt sich sowohl im Bildlichen als auch in der
              wahrnehmbaren Raumabfolge des Gebäudes wider. Orthogonale Linien
              weisen dem Besucher den Weg, Knotenpunkte werden zu Treffpunkten
              und Aufenthaltsräumen. Die Fassade reagiert auf die Dynamik der
              angrenzenden Hauptverkehrstrasse, die horizontalen Lamellen
              zeichnen ein Spiel der Transparenzen nach Außen und verschiedenste
              Lichtstimmungen nach Innen. Gegenüber der bestehenden Oper
              spiegelt der Neubau die Energie der Jugend wider und artikuliert
              sein Treiben in den öffentlichen Raum.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s02}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              In unmittelbarer Beziehung zum Außenraum lässt sich im Erdgeschoss
              zu allen Seiten Aufschluss über die innere Funktion des Gebäudes
              geben. Besonders im Rückbereich wurde explizit auf eine massive
              Sockelausbildung verzichtet und stattdessen Öffnungen vorgesehen,
              die den Passanten Einblicke in tiefergelegte Proberäume
              ermöglicht.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s03}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s03);
            }}
          />
          <img
            className="arcimg"
            src={s04}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s04);
            }}
          />
          <img
            className="arcimg"
            src={s05}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s05);
            }}
          />
          <img
            className="arcimg"
            src={s06}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s06);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Bühnen, sowie deren Zuschauerräume werden innerlich, sowie
              äußerlich als zwei hölzerne Solitäre ausgebildet und schweben
              förmlich in dem leichten Linienkonstrukt. Die zentralen
              Fluchttreppenhäuser, sowie unregelmäßige Stützen im Foyer und
              massive Wandscheiben im Rückbereich des Gebäudes tragen die Lasten
              der oberen Geschosse. Unterzüge ermöglichen die größeren Lager und
              Veranstaltungsräume stützenfrei auszubilden.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s07}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s07);
            }}
            style={{ marginBottom: "1rem" }}
            ></img>
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Das Gebäude greift die Materialität des Opernhauses mit seiner
              patinierten Kupferfläche im Außenraum und seiner mittels
              Lichtinstallation illuminierten Holzverkleidungen des Innen-raumes
              auf und interpretiert diese für ein junges Publikum. Dank
              innenliegender Medienfassaden und dynamischer Lichtkunst erstrahlt
              das Bauwerk in den Abendstunden in einem einladenden Licht- und
              Farbspiel.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s08}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s08);
            }}
          ></img>
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
