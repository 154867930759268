import React from "react";
import s01 from "../../img/ARCH/Arch_VBG/01.jpg";
import s02 from "../../img/ARCH/Arch_VBG/02.jpg";
import s03 from "../../img/ARCH/Arch_VBG/03.jpg";
import s04 from "../../img/ARCH/Arch_VBG/04.jpg";
import "../../css/archi.css";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IArchVbgState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const ArchVbgComponent: React.FC<IArchVbgState> = () => {
  const [state, setState] = React.useState<IArchVbgState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02, s03, s04];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IArchVbgState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickForward()}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickBackward()}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <h1>VBG</h1>
            <span style={{ color: "grey" }}>_Architektur_Machbarkeitsstudie</span>
            <h4>spine architects | 2019</h4>
            <span style={{ color: "grey" }}>
              Kooperation mit dem Büro spine aus Hamburg für die Neuplanung des
              Firmensitzes der VBG Versicherung am Standort Duisburg.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>

      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Machbarkeitsstudie befasst sich mit Frage ob der betrachtete
              Standort für das geforderte Raumprogramm grundsätzlich geeignet
              ist. Hierzu wurden verschiedene Varianten der Grundrisse erstellt
              und interne Funktionalität mit Hinblick auf die spätere Nutzung
              überprüft.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s02}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s03}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s03);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              In der anschließenden Vertiefung der Studie wurde die
              Aussenwahrnehmung des Gebäudes mittels Fassadenvarianten
              überprüft. Diese wurden parametrische erzeugt und reagieren auf
              die jeweils anliegende Nutzungstypologie.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s04}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s04);
            }}
          />
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
