import React from "react";
import "../css/start.css";
import archsonnenpark from "../img/start/01_Arch_Sonnenpark.jpg";
import archjbd from "../img/start/01_Arch_jbd.jpg";
import archolang from "../img/start/01_Arch_olang.jpg";
import archtinnweg from "../img/start/01_Arch_tinnumerweg.jpg";
import archvbg from "../img/start/01_Arch_vbg.jpg";
import { Link } from "react-router-dom";

export interface IStartState {
  category: string;
}

export const ArchitectureComponent: React.FC<IStartState> = () => {
  const sectionArch = (): JSX.Element => (
    <div className="visible">
      <Link to={"/architecture/tinnumerweg"}>
        <img
          src={archsonnenpark}
          alt="sonnenpark"
          className="img"
          title="Sonnenpark"
        />
      </Link>
    </div>
  );

  const sectionArch1 = (): JSX.Element => (
    <div className="visible">
      <Link to={"/architecture/tinnumerweg"}>
        <img
          src={archtinnweg}
          alt={"Tinnumer Weg"}
          className="img"
          title={"Tinnumer Weg"}
        />
      </Link>
    </div>
  );

  const sectionArch2 = (): JSX.Element => (
    <div className="visible">
      <Link to={"/architecture/jbd"}>
        <img src={archjbd} alt={"JBD"} className="img" title={"JBD"} />
      </Link>
      <Link to={"/architecture/olang"}>
        <img src={archolang} alt={"Olang"} className="img" title={"Olang"} />
      </Link>
      <Link to={"/architecture/vbg"}>
        <img src={archvbg} alt={"VBG"} className="img" title={"VBG"} />
      </Link>
    </div>
  );

  const getVirText = (): JSX.Element => (
    <label className="txt">
      <Link to="/home">
        <span className="grey">Hallo.</span>
      </Link>
      <span className="grey">
        Wir sind{" "}
        <span className="black">
          <Link to="/about">VIR.works</Link>
        </span>.
      </span>
      <span className="grey">
        Wir arbeiten an {""}
        <Link to="/architecture">
          <span className="black">Architektur</span> und{" "}
        </Link>
        <Link to="/communication">
          <span className="black">Kommunikation</span>
        </Link>.
      </span>
      <span className="grey">
        Wir freuen uns von{" "}
        <span className="black">
          <Link to="/kontakt">Dir</Link>
        </span>{" "}
        zu Hören.
      </span>
    </label>
  );

  return (
    <>
      <div className="imgtxt_wrapper">
        {getVirText()}
        <div className="cntnr">
          {sectionArch()}
          {sectionArch1()}
          {sectionArch2()}
        </div>
      </div>
      <footer className="footer">
        Copyright VIR.works - Göbel Fuchte GbR - 2021 | Impressum | Datenschutz
      </footer>
    </>
  );
};
