import React from "react";
import s01 from "../../img/ARCH/Arch_Tinnumer Weg/01.jpg";
import s02 from "../../img/ARCH/Arch_Tinnumer Weg/02.jpg";
import s03 from "../../img/ARCH/Arch_Tinnumer Weg/03.jpg";
import s04 from "../../img/ARCH/Arch_Tinnumer Weg/04.jpg";
import s05 from "../../img/ARCH/Arch_Tinnumer Weg/05.jpg";
import s06 from "../../img/ARCH/Arch_Tinnumer Weg/06.jpg";
import s07 from "../../img/ARCH/Arch_Tinnumer Weg/07.jpg";
import s08 from "../../img/ARCH/Arch_Tinnumer Weg/08.jpg";
import "../../css/archi.css";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IArchTinnwegState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const ArchTinnwegComponent: React.FC<IArchTinnwegState> = () => {
  const [state, setState] = React.useState<IArchTinnwegState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02, s03, s04, s05, s06, s07, s08];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IArchTinnwegState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickForward()}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickBackward()}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <h1>Tinnumer Weg</h1>
            <span style={{ color: "grey" }}>_Architektur_Wettbewerb</span>
            <h4>spine architects | 2020</h4>
            <span style={{ color: "grey" }}>
              Wettbewerbs-Kooperation mit dem Büro spine aus Hamburg für die
              Sanierung der Wohnanlage Tinnumer Weg in Hamburg Billstedt. Der
              Entwurf erzielte den zweiten Platz in der Ideenkonkurenz.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>

      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Wohnanlage Tinnumer Weg mit ca. 250 Ein- bis
              Vierzimmer-Wohnungen wurde zu Beginn der 70er Jahre er richtet.
              Zahlreiche Modernisierungsmaßnahmen sind in den letzten Jahren
              durchgeführt worden, um das Wohnen an diesem Standort mit seiner
              sehr guten ÖPNV-Anbindung wieder attraktiv und lebenswert zu
              gestalten. Es besteht jedoch auch weiterhin der dringende Wunsch,
              die Qualität und die Außenwahrnehmung des Gesamtensemble über die
              bereits er folgten Maßnahmen hinaus zu steigern, das direkte
              Umfeld zu verbessern und zu aktivieren.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s02}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Hierzu gehört u.a. die Sanierung der gesamten Gebäudehülle, eine
              Verbesserung der Eingangssituationen bei den einzelnen Häusern,
              eine Neuordnung der erdgeschossigen Gewerbezone inkl. der
              dazugehörigen Anlieferungszone auf Ebene -1 (Hochhaus), eine neue
              und lebendige Gestaltung der Außenanlagen und der Entwurf einer
              Kita für 80-100 Kinder.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s03}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s03);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Diese Maßnahmen, die auch als direktes Aufgabenpaket im Rahmen des
              Ideenwettbewerbes ausgesprochen worden sind, haben wir in einzelne
              Bausteine aufgegliedert, so dass eine sukzessive Umsetzung der
              einzelnen Vorschläge möglich ist.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s04}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s04);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s05}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s05);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Gestaltung der Eingänge aller Gebäude folgt den selben
              Grundprinzipien. Die Eingangsbereiche werden zweigeschossig
              geöffnet, etwas aus der Fassade gedrückt, um auch innerhalb des
              Foyers eine entsprechende Großzügigkeit zu ereichen. Das neue
              Material- und Farbkonzept vereint sowohl visuelle Wegeführung,
              Identifikation für die Bewohner und robuste Oberflächen.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s06}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s06);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die vorhandenen Fassadenpaneele werden zurückgebaut und durch eine
              neue Fassadenbekleidung ersetzt. Aufbauend auf dem ursprünglichen
              Fassadenbild einer zusammenhängenden, stark gegliederten
              Horizontalität, werden die neuen, leicht strukturierten
              Metallpaneele im Bereich der Fensterbänder geschossweise mit
              leichten Farbnuancen ausgeführt. Sie verleihen so dem Gebäude,
              eine Lebendigkeit, ohne einen vollständig anderen Weg der
              Fassadengestaltung einzuschlagen. Die Fassadenpaneele im Bereich
              der Brüstungsbänder sind dagegen mit einer leicht glänzenden
              cremeweißen Eloxalbeschichtung ausgeführt, die in Teilbereichen
              mit einem leichten Knick im Bereich einzelner Balkone versehen
              sind und so zusätzliche Möglichkeiten einer individuellen
              Ausformung der Balkone ermöglichen.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s07}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s07);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die vorgeschlagenen Maßnahmen sollen eine Ideenvielfalt
              widerspiegeln und Potentiale eröffnen, welche Veränderungen mit
              den einzelnen Bausteinen möglich sind und wie die Akzeptanz der
              Bewohner mit Ihrem unmittelbaren Umfeld und ihrer direkten
              Behausung erhöht werden kann. Die Vorschläge könnten z.B. im
              Rahmen einer Befragung der Bewohner vorgestellt werden, um ein
              Meinungsbild zu erhalten, welche dieser Maßnahmen von den
              Bewohnern als dringlichste und/ oder begrüßenswerteste angesehen
              werden.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s08}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s08);
            }}
          />
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
