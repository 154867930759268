import React from "react";
import s01 from "../../img/ARCH/Arch_Sonnenpark/01.jpg";
import s02 from "../../img/ARCH/Arch_Sonnenpark/02.jpg";
import s03 from "../../img/ARCH/Arch_Sonnenpark/03.jpg";
import s04 from "../../img/ARCH/Arch_Sonnenpark/04.jpg";
import "../../css/archi.css";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IArchSonnenparkState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const ArchSonnenparkComponent: React.FC<IArchSonnenparkState> = () => {
  const [state, setState] = React.useState<IArchSonnenparkState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02, s03, s04];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IArchSonnenparkState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickForward()}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickBackward()}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <h1>Sonnenpark</h1>
            <span style={{ color: "grey" }}>_Architektur_Wettbewerb</span>
            <h4>ZHAC - Zweering Helmus Architekten Part GmbB | 2019</h4>
            <span style={{ color: "grey" }}>
              Wettbewerbs-Kooperation mit dem Büro ZHAC aus Aachen und dem
              Landschaftsarchitekurbüro Kraftraum für die Neuplanung eines
              Areals am Sonnenpark in Düsseldorf Oberbilk. Der Entwurf erzielte
              den zweiten Platz in der Preisgerichtssitzung.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s02}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>

      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Im wesentlichen besteht die Neuplanung aus drei Hauptnutzungen:
              Geschäftsnutzung mit Supermarkt, Bäcker, und Drogerie im EG.
              Gefördertem Wohnungsbau in den östlichen Obergeschossen, sowie
              einem Boardinghouse für studentisches Wohnen im westlichen Teil.
              Die Ausrichtung des Grundstücks sowie die hohe Lärmbelästigung der
              Hauptstraße machte die Nutzung- und Wohnungsaufteilung allerdings
              zu einer Herausforderung.{" "}
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Besonderes Augenmerk lag auf der Fassade. Hier wurden diverse
              Varianten am Bild überprüft, sodass am Ende eine stimmige helle
              Klinkerfassade mit ansprechenden Details entstand.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s03}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s03);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Der Innenhof des Gebäudes wird als grüner Rückzugsort für alle
              Bewohner geplant. Hier war es wichtig die besondere
              Aufenthaltsqualität zu zeigen, die im starken Kontrast zur lauten
              Hauptstraße vor dem Gebäude steht.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s04}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s04);
            }}
          ></img>
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
