import React from "react";
import s01 from "../img/about/01.jpg";
import s02 from "../img/about/02.jpg";
import s03 from "../img/about/03.jpg";
import s04 from "../img/about/04.jpg";
import s05_lp from "../img/about/05_lp.jpg";
import s06_nm from "../img/about/06_nm.jpg";
import s07_spine from "../img/about/spine.jpg";

import "../css/about.css";

export const AboutComponent: React.FC = () => {
  return (
    <div className="divwrapper">
      <div className="imgtxt-wrapper">
        <div className="imgtxt mb">
          <figure>
            <figcaption className="figcap">
              VIR.works &nbsp;
              <span className="subtext">
                ist ein Studio für Architektur und Kommunikation. Wir arbeiten
                für und vor allem mit Architekturbüros, Investoren sowie
                privaten / öffentlichen Bauherren. Seit 2019 sind wir als
                Lehrbeauftragte an der MSA tätig. Zudem unterstützen wir
                verschiedene Institutionen mit Vorträgen und Workshops.
              </span>
            </figcaption>
            <img src={s01} alt="beneniklas"></img>
          </figure>
        </div>
        <div className="imgtxt mb">
          <figure>
            <figcaption className="figcap">
            <span>Wie wir angefangen haben?</span>
              <br />
              <span className="subtext">
              Unser gemeinsames Büro VIR.works entstand direkt nach 
              dem Architekturstudium.
              Bis 2017 haben wir zusammen an der Münster School of Architecture 
              unseren Master-Studiengang absolviert. Bereits im 
              Bachelorstudiengang an der FH Dortmund kreuzten sich unsere Wege. 
              Gemeinsam haben wir unsere Zeit in den analogen und digitalen 
              Werkstätten der Hochschulen verbracht
              , da wir das aktive Treiben und den ständigen 
              Austausch mit unseren Kommilitonen als perfekte Arbeitsumgebung 
              empfanden.
                <br />
                <br />
                Der Umgang und das Beherrschen verschiedenster "Werkzeuge" 
                war während unseres 
                Studiums ein wichtiger Aspekt an dem wir bis heute festhalten.  
              </span>
            </figcaption>
            <img src={s02} alt="beneniklas"></img>
          </figure>
        </div>
        <div className="imgtxt mb">
          <figure>
            <figcaption className="figcap">
            <span>Wer wir sind? </span>
              <br />
              <span className="subtext">
              Wir sind Benedikt und Niklas. Wir sind Architekten. 
              Unser Schwerpunkt ist die Architektur-Kommunikation. 
              Diese hat unserer Auffassung nach viele Facetten. 
              Egal ob in Form von Visualisierungen, bildhafter Darstellungen 
              oder Modellen, Ziel ist es Architektur zu vermitteln.
              <br />
              <br />
              Um architektonische Konzepte für 
              jedermann verständlich zu machen, 
              sehen wir die Bilder ungebauter Architektur 
              nicht nur als ihr visuelles Abbild, sondern 
              als eine fiktive Geschichte die wir erzählen. 
              </span>
            </figcaption>
            <img src={s03} alt="beneniklas"></img>
          </figure>
        </div>
        <div className="imgtxt mb">
          <figure>
            <figcaption className="figcap">
              <span>Woran wir arbeiten? </span>
              <br />
              <span className="subtext">
              Einen großen Teil unserer Arbeit machen Visualisierungen 
              ungebauter Architektur aus, zum Beispiel für Architektur-Wettbewerbe 
              oder zu Marketingzwecken. In der letzen Zeit verstärkt 
              sich unsere Tätigkeit in Richtung klassischer Planungsaufgaben 
              sowie Machbarkeitsstudien und Entwürfe. Hierbei setzen wir 
              unsere Fähigkeiten gewinnbringend 
              ein und überzeugen mit neuen Arten der Darstellung.
              <br />
              <br />
              Auch mit Hilfe neuer Technologien versuchen wir die 
              Beteiligten auf vielfältige Weise 
              abzuholen und so das jeweilige Projekt voran zu bringen.
              </span>
            </figcaption>
            <img src={s04} alt="beneniklas"></img>
          </figure>
        </div>
        <div className="imgtxt mb">
          <figure>
            <figcaption className="figcap">
              Wo wir arbeiten? <br />
              <span className="subtext mb">
              Seit 2020 arbeiten wir in unserem charmanten 
              Hinterhof-Büro im Dortmunder Gerichtsviertel. 
              Dieses teilen wir uns als Bürogemeinschaft mit 
              anderen kreativen Köpfen. Das Gebäude haben wir in 
              Eigenarbeit renoviert und nach unseren Vorstellungen umgestaltet. 
              Unser Studio verstehen wir als offenen, kommunikativen 
              und kreativen Spielplatz gleichgesinnter Menschen die einen positiven 
              Einfluss und Beitrag auf ihre gebaute Umwelt hinterlassen wollen.
              </span>
              <br />
              <br />
              <span className="subtext">
                Zukünftig sollen auch Vorträge, Veranstaltungen und Filmabende
                in unserem Haus stattfinden die allen Interessierten offen
                stehen.
              </span>
              <br />
              <br />
              <br />
            </figcaption>
            <div className="logo_wrapper">
              <a
                href="https://www.lessplus.de"
                target="_blank"
                rel="noreferrer" 
                style={{marginRight:'2px'}}
              >
                <img src={s05_lp} alt="less-plus" className="logo_img"></img>
              </a>
              <a
                href="https://nilsmartens-architektur.de"
                target="_blank"
                rel="noreferrer"
                style={{marginRight:'2px'}}

              >
                <img src={s06_nm} alt="nm" className="logo_img"></img>
              </a>
              <a
                href="https://www.spine-architects.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={s07_spine} alt="spine" className="logo_img"></img>
              </a>
            </div>
            <br />
            <div style={{marginBottom: '5rem'}}>
              <span>Mit wem wir arbeiten?</span>
              <span className="subtext">
                <br /> Was uns sehr freut ist, dass sich in den letzten Jahren
                aus einigen Arbeitskontakten Freundschaften entwickelt haben.
                Dies ist Motivation und Antrieb für jedes weitere gemeinsame
                Projekt.
              </span>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};
