import React from "react";
import s01 from "../../img/ARCH/Arch_Olang/01.jpg";
import s02 from "../../img/ARCH/Arch_Olang/02.jpg";
import s03 from "../../img/ARCH/Arch_Olang/03.jpg";
import s04 from "../../img/ARCH/Arch_Olang/04_GIF.gif";
import s05 from "../../img/ARCH/Arch_Olang/05_GIF.gif";
import s06 from "../../img/ARCH/Arch_Olang/06_GIF.gif";
import s07 from "../../img/ARCH/Arch_Olang/07.jpg";
import s08 from "../../img/ARCH/Arch_Olang/08.jpg";
import "../../css/archi.css";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../styles/styles";

export interface IArchOlangState {
  src: string;
  zoom: boolean;
  slideIndex: number;
}

export const ArchOlangComponent: React.FC<IArchOlangState> = () => {
  const [state, setState] = React.useState<IArchOlangState>({
    src: "",
    zoom: false,
    slideIndex: -1,
  });

  const classes = useStyles();

  const slides = [s01, s02, s03, s04, s05, s06, s07, s08];

  const setSliderImgIndex = (src: string) => {
    const slIndex = slides.findIndex((slide) => slide === src);
    setState((state: IArchOlangState) => ({
      ...state,
      slideIndex: slIndex,
    }));
  };

  const handleClickForward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === maxIndex) {
      setState({
        ...state,
        slideIndex: 0,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex + 1,
      });
    }
  };

  const handleClickBackward = () => {
    const maxIndex = slides.length - 1;
    if (state.slideIndex === 0) {
      setState({
        ...state,
        slideIndex: maxIndex,
      });
    } else {
      setState({
        ...state,
        slideIndex: state.slideIndex - 1,
      });
    }
  };

  const slideForward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickForward()}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const slideBackward = (): JSX.Element => (
    <Button style={{ color: "black" }} onClick={() => handleClickBackward()}>
      <ArrowBackIosIcon />
    </Button>
  );

  return (
    <div className="imgtxt-wrapper">
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <h1>Olang</h1>
            <span style={{ color: "grey" }}>_Architektur_Projektmitarbeit</span>
            <h4>studio schlotthauer matthiessen - architecturemade | 2018</h4>
            <span style={{ color: "grey" }}>
              Projektmitarbeit beim Büro architecturemade aus Hamburg für die
              Neuplanung der Aufstiegsanlagen Olang 1+2 am Kronplatz in
              Südtirol.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s01}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s01);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>

      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Der Entwurf des Büros architecturemade erzielte beim
              Einladungswettbewerb im Jahr 2018 den ersten Platz. Während der
              Wettbewerbsphase wurden wir mit der Erstellung von
              Visualisierungen beauftragt. In den folgenden Planungsphasen
              konnten wir außerdem, als freie Mitarbeiter das Projekt unter
              Leitung von Cornelius Schlotthauer bis in die Ausführungsplanung
              begleiten.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s02}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s02);
            }}
            style={{ marginBottom: "1rem" }}
          />
        </figure>
      </div>
      <div className="imgtxt">
        <figure>
          <figcaption className="figcap">
            <span style={{ color: "grey" }}>
              Die Planungungsaufgabe umfasste drei Stationen der Aufstiegsanlage
              am Kronplatz in Olang, Südtirol.
            </span>
          </figcaption>
          <img
            className="arcimg"
            src={s03}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s03);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s04}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s04);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s05}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s05);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s06}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s06);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s07}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s07);
            }}
            style={{ marginBottom: "1rem" }}
          />
          <img
            className="arcimg"
            src={s08}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setSliderImgIndex(s08);
            }}
          />
        </figure>
      </div>
      <Modal className={classes.root} open={state.slideIndex !== -1}>
        <div className={classes.modal}>
          <Button
            className="btn"
            onClick={() => setState({ ...state, slideIndex: -1 })}
          >
            <span style={{ color: "black" }}>X</span>
          </Button>
          <div className="sliback">{slideBackward()}</div>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              alignSelf: "center",
            }}
          >
            <img
              src={slides[state.slideIndex]}
              className={classNames({
                modalimg: true,
                "zoom-in": state.zoom,
              })}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setState({ ...state, zoom: !state.zoom });
              }}
            ></img>
          </div>
          <div className="slifor">{slideForward()}</div>
        </div>
      </Modal>
    </div>
  );
};
